.choose_cont {
    margin-top: 10%;
    max-height: 400px;
    overflow: auto;

    .individual-car {
        border: 1px solid #ddd;
        display: flex;
        border-radius: 6px;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 10px;
        align-items: center;

        &:hover {
            background-color: #fff3c3;
        }

        .car-left {
            background: var(--joy-palette-primary-solidBg);
            padding: 15px;
            border-radius: 5px;
            margin-right: 10px;

            svg {
                font-size: 30px;
            }
        }

        .delete-icon {
            color: red;
        }

        .car-right {
            flex: 6;
        }

        .model {
            text-align: left;
            display: block;
            color: #aaa;
        }

        .license {
            display: block;
            text-align: left;
            font-weight: bold;
            color: #333;
        }
    }
}
.licenseImg{
    width: 50%;
}
.dialog-content {
    // min-width: 35vw;
    text-align: center;

    @media only screen and (min-width: 600px) {
        width: 410px;
    }

    .error {
        color: red;
        font-weight: 600;
        font-size: 14px;
    }

    .button-cont {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .Button {
            margin: 20px;
        }
    }
}

.dialog-delete {
    .button-cont {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .Button {
            margin: 10px;
        }
    }
    img{
        width: 100%;
    }
}