@import './assets/scss/fonts.scss';
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  // font-family: 'Righteous', cursive;
  font-family: 'frank';
  // font-family: 'Open Sans', sans-serif !important;
  transition: all ease 0.5s;
}

:root {
  --primary-yellow: #FCCE01;
  --primary-black: #353535;
  --primary-red: #f32222;
  --joy-palette-primary-outlinedBorder: #353535;
  --joy-palette-primary-solidBg: #FCCE01;
  --joy-palette-primary-outlinedHoverBg: #fff5c9;
  --joy-palette-primary-outlinedColor: #353535;
  --joy-palette-primary-solidHoverBg: #ecc101;
}


*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .2);
  background-color: #aaa;
}


@import "./assets/scss/common.scss";