h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    color: var(--primary-black);
}

.MuiSelect-root {
    padding: 17px !important;
    transition: all ease 0s !important;
}

.MuiSelect-root,
.MuiInput-input,
.MuiOption-root,
.MuiInputBase-input,
.MuiModalDialog-root,
.MuiTooltip-root {
    font-family: 'frank' !important;
}

.MuiSelect-listbox,
.MuiPickersLayout-root,
.MuiPickersPopper-root,
.MuiTooltip-root {
    transition: all ease 0s !important;
}

.MuiCheckbox-colorPrimary {
    color: var(--primary-yellow) !important;
}

.MuiSelect-listbox {
    .MuiOption-root {
        &:hover {
            background-color: #fff5c9 !important;
        }
    }
}

.MuiPickersDay-root {
    &.Mui-selected {
        background-color: var(--primary-yellow) !important;
    }

}

.MuiInputLabel-root {
    .mandatory {
        color: red;
    }
}

.custom-phone {
    padding: 15px 20px;
    width: 100%;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.PhoneInput {
    border: 2px solid #D4D0D0;
    border-radius: 5px;

    .PhoneInputCountry {
        margin-left: 10px;
    }

    .PhoneInputInput {
        padding: 15px 20px;
        width: 100%;
        font-size: 16px;
    }
}

.MuiModalDialog-root {
    min-width: 25vw !important;

    @media only screen and (max-width: 600px) {
        min-width: 95vw !important;
    }
}

.MuiAccordionSummary-button {
    font-weight: bold !important;
}

.MuiInput-root{
    outline: var(--primary-yellow) !important;
    &::before{
        box-shadow: var(--Input-focusedInset, inset) 0 0 0 calc(var(--Input-focused) * var(--Input-focusedThickness)) var(--primary-yellow) !important;
    }
}

.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
        border-color: var(--primary-yellow) !important;
    }

}

.MuiFormLabel-root {
    &.Mui-focused {
        color: var(--primary-yellow) !important;

    }
}