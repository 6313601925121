.OTPBox {
    display: block;
    width: 100%;

    input {
        border: 1px solid #aaa;
        padding: 25px 10px;
        border-radius: 5px;
        display: inline-block;
        width: 60px;
        text-align: center;
        margin: 5px 5px;
        font-size: 24px;
        font-weight: bold;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}