.ErrorPage{
    display: block;
    text-align: center;
    margin-top: 10%;
    img{
        width: 250px;
    }
    h4{
        font-size: 30px;
    }
    .Button{
        display: inline-block;
        margin-top: 26px;
    }
}
