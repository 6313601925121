.payment{
    text-align: center;
    h2{

    }
    p{
        padding: 10px;
    }
    .Button{
        display: inline-block;
    }
    img{
        width: 20%;
    }
}