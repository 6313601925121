.car-details {
    border-radius: 3px;

    h4 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .row-inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;

        @media only screen and (max-width: 600px) {
            display: block;
        }
    }

    .column-inputs {
        width: 33%;

        @media only screen and (max-width: 600px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .button-cont {
        display: flex;
        justify-content: space-between;
    }

}

.dialog-content {
    .OTPBox {
        padding: 0% 15%;
        @media only screen and (max-width: 600px) {
            padding: 0% 0% !important;
        }
    }

    h2 {
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 15px;
    }
}
.mobile-navig {
    display: none;
    margin-bottom: 12px;
    align-items: center;
    @media only screen and (max-width: 600px) {
        display: flex;
    }

    .icon-div {
        padding: 10px 12px;
        text-align: center;
        border-radius: 4px;
        box-shadow: 0px 0px 3px 3px #ddd;

    }
    .heading{
        font-weight: bold;
        margin-left: 30%;
    }
}
.enginesize-comp{
    // width: 100%;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
        display: block;
    }
    &.disabled{
        .single-engine{
            cursor: not-allowed;
            &:hover{
                background-color: #F9F9F9;
            }
            &.active{
                background-color: #ddd;
            }
        }
    }
    .single-engine{
        background-color: #F9F9F9;
        padding: 30px 30px;
        border: 1px solid #aaa;
        min-width: 200px;
        cursor: pointer;
        border-radius: 4px;
        text-align: center;
        margin-right: 20px;
        
        @media only screen and (max-width: 600px) {
            margin-bottom: 10px;
        }
        &:hover{
            background-color: var(--joy-palette-primary-outlinedHoverBg);
        }
        &.active{
            border: 2px solid var(--primary-yellow);
        }
        .img-cont{
            img{

            }
        }
        span{

        }
    }
}