.InputBox {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;

    input {
        display: block;
        padding: 15px 20px;
        width: 100%;
        border: 2px solid #D4D0D0;
        font-size: 16px;
        border-radius: 5px;

        &:focus {
            border-color: var(--joy-palette-primary-solidHoverBg);
            outline-color: var(--joy-palette-primary-solidHoverBg);
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .label {
        font-weight: 500;
        font-size: 14px;
        display: block;
        margin-bottom: 5px;
    }

    &.error {
        input {
            border-color: var(--primary-red);
            outline-color: var(--primary-red);
        }
    }

    .error {
        color: var(--primary-red);
        font-size: 14px;
    }
}