.form-cont {
    padding: 0% 5%;
    .MuiInputLabel-root{
        font-weight: 600;
        margin-bottom: 10px;
        @media only screen and (max-width: 600px) {
            margin-bottom: 5px;
        }
    }
    .MuiSelect-select, .MuiInputBase-input{
        font-weight: 600;
    }
}
.MuiStepIcon-root{
    &.Mui-completed{
        color: #fcce01 !important;
    }
}
.MuiStepLabel-root{
    .Mui-disabled{
        .MuiSvgIcon-root{
            color: #ddd;
        }
    }
}
.MuiStepConnector-root{
    &.MuiStepConnector-horizontal{
        &.Mui-disabled{
            .MuiStepConnector-line{
                border-color: #eee;
            }
        }
    }
}

.progress-cont {
    padding: 0% 5%;

    .progress {
        border-radius: 3px;
    }

    .MuiSvgIcon-root {
        color: #fcce01;
    }

    .Mui-active {
        color: #fcce01 !important;
    }

    .MuiStepConnector-line {
        border-color: #fcce01;
        border-top-width: 5px;
    }
    .MuiStepIcon-text{
        fill: #404040;
        font-weight: bold;
    }
}

.card {
    box-shadow: 0px 0px 5px 3px #f2f2f2;
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.flex {
    display: flex;
}