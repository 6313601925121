.HomePage {
    overflow-y: hidden;

    .header {
        position: absolute;
        padding: 10px 4%;
        text-align: center;

        @media only screen and (max-width: 600px) {
            padding: 10px 20%;
            position: relative;
        }
    }

    .logo {
        width: 250px;

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }

    .cara {
        width: 70%;
        margin-left: -23%;
        margin-top: 45%;
        @media only screen and (min-width: 600px) and (max-width: 1000px) {
            margin-top: 90%;
        }
        @media only screen and (min-width: 1000px) and (max-width: 1200px) {
            margin-top: 80%;
        }
        @media only screen and (min-width: 1200px) {
            margin-top: 60%;
        }
    }

    .division {
        display: flex;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }

        .div-block {
            height: 100vh;
            // flex: 1 0;
        }

        .carmobile {
            display: none;

            @media only screen and (max-width: 600px) {
                display: block;
                transform: rotate(90deg);
                position: relative;
                left: -90px;
                top: 40px;
            }
        }

        .backtoweb {
            display: none;

            @media only screen and (max-width: 600px) {
                display: flex;
                align-items: center;
            }
        }

        .left {
            padding: 5%;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .heading {
                font-family: 'frank', cursive !important;
                font-size: 40px;
                color: var(--primary-black);
                width: 40%;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                    font-size: 25px;
                    margin-top: 10%;
                    background: #fff;
                    padding: 20px 0px 0px 0px;
                    z-index: 1;
                }
            }

            p {
                margin-top: 20px;
                width: 50%;
                z-index: 1;
                background-color: #fff;
                font-size: 18px;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                    margin-top: 0px;
                    font-size: 16px;
                }
            }

            .button-cont {
                display: flex;
                width: 50%;
                margin-top: 30px;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                    display: block;
                }

                .Button {
                    flex: 1;
                    margin-right: 20px;

                    @media only screen and (max-width: 600px) {
                        margin-right: 20px;
                        margin: 10px 0px;
                    }
                }
            }
        }

        .right {
            display: block;
            width: 77%;
            background-color: var(--primary-yellow);

            @media only screen and (max-width: 600px) {
                display: none;
            }

            .Button {
                position: absolute;
                right: 30px;
                top: 30px;
            }
        }
    }
}

.button-book-cont {
    margin-top: 15px;
}