.main-grid {
    display: grid;
    grid-template-columns: 65% 35%;
    gap: 10px;

    @media only screen and (max-width: 600px) {
        display: block;
    }

    .heading {
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;

        svg {
            margin-right: 10px;
            vertical-align: middle;
        }
    }
}

.grid-50 {
    display: grid;
    grid-template-columns: 49% 50%;
    gap: 10px;
}

.bookingOverview {
    display: block;
    margin-top: 20px;

    .booking-item {
        border-bottom: 1px solid #ddd;
        padding: 12px 0px;

        .price-cont {
            display: flex;
            justify-content: space-between;

            .category {
                font-weight: 600;
                font-size: 16px;
                flex: 3;
            }

            .total {
                font-weight: 600;
                font-size: 16px;
                color: #dcb300;
            }

            .delete {
                width: 25px;
                flex: 0 1 0%;
            }
        }

        .sub-category {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 13px;
                color: #aaa;
            }

            .delete-item {
                cursor: pointer;
            }
        }
    }

    .coupon {
        margin: 10px 0px;
    }
}

.mandatory {
    color: red;
    margin-left: 2px;
}

.service-tabs {
    margin-top: 10px;
    transition: all ease 0.5s;

    .headers {
        span {
            display: inline-block;
            padding: 15px 20px;
            font-weight: 500;
            margin-bottom: -3px;
            transition: all ease 0.5s;
            color: #A1A1A1;
            cursor: pointer;

            svg {
                margin-right: 10px;
                vertical-align: middle;

                path {
                    fill: #A1A1A1;
                }
            }

            &.active {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border: 1px solid #aaa;
                background: #fff;
                color: #333;
                border-bottom: 0px;

                svg {
                    path {
                        fill: #333;
                    }
                }
            }
        }
    }

    .tab-icon {
        min-width: 16px;
        margin-right: 10px;
        vertical-align: middle;
    }

    .tab-sub-cats {
        border: 1px solid #aaa;
        padding: 10px;
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;
        transition: all ease 0.5s;

        span {
            padding: 15px;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
            font-weight: 500;
            background-color: #f1f1f1;
            border-radius: 4px;
            transition: all ease 0.5s;

            &:hover {
                background-color: var(--joy-palette-primary-outlinedHoverBg);
            }
        }

        .selected {
            background-color: var(--primary-yellow);

            &:hover {
                background-color: var(--joy-palette-primary-solidHoverBg);
            }
        }
    }
}

.button-cont-serv {
    display: flex;
    padding: 15px;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        display: none;
    }

    .Button {
        width: 49%;
    }
}

.mobile-button-cont-serv {
    display: none;

    @media only screen and (max-width: 600px) {
        display: block;
    }

    .Button {
        margin-bottom: 10px;
    }
}

.coupon-applied {
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .left-side {
        display: flex;
        align-items: center;

        span {
            margin-left: 10px;
        }
    }
}
.additional-services {
    // margin: 20px;
    // padding: 20px;
    // border-radius: 8px;
    // box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Soft shadow for depth */
  }
  
  .service-item {
    margin-bottom: 15px; /* Space between service items */
    padding: 10px;
    border-radius: 4px;
    background-color: #f9f9f9a6; /* Light background for each item */
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkbox-label input[type='checkbox'] {
    margin-right: 10px; /* Space between checkbox and description */
  }
  
  .address-input {
    margin-top: 10px; /* Space between checkbox and address input */
  }
  
  .address-input input {
    width: 100%;
    padding: 8px;
    border: 1px solid #cccccc9f; /* Subtle border for the input */
    border-radius: 4px; /* Rounded corners for the input field */
    box-sizing: border-box; /* Ensures padding does not add to the width */
  }
  
.mobile-services {
    .mobile-navig {
        display: flex;
        margin-bottom: 12px;
        align-items: center;

        .icon-div {
            padding: 10px 12px;
            text-align: center;
            border-radius: 4px;
            box-shadow: 0px 0px 3px 3px #ddd;

        }

        .heading {
            font-weight: bold;
            margin-left: 30%;
        }
    }
}

.coupon-cont {
    padding: 12px;

    .error {
        display: block;
        font-weight: 500;
        margin-left: 5px;
        font-size: 14px;
        margin-top: 5px;
        color: red;
    }

    .success {
        display: block;
        font-weight: 500;
        margin-left: 5px;
        font-size: 14px;
        margin-top: 5px;
        color: green;
    }

    .coupon {
        display: flex;
        justify-content: space-between;

        .promo-input {
            margin-right: 10px;
            padding: 13px 17px !important;
            width: 100%;
        }
    }
}
#confirm-dialog-later-description ul {
    padding-left: 20px;  /* Adjusts the indentation of the list */
    margin: 0;           /* Removes default margin */
}

#confirm-dialog-later-description li {
    margin-bottom: 8px;  /* Adds space between list items */
}
#confirm-dialog-description ul {
    padding-left: 20px;  /* Adjusts the indentation of the list */
    margin: 0;           /* Removes default margin */
}

#confirm-dialog-description li {
    margin-bottom: 8px;  /* Adds space between list items */
}

.estimate {
    padding: 5px 15px;

    .label {
        font-weight: bold;
    }

    .right {
        float: right;
        font-weight: bold;
    }

    .error {
        color: red;
    }

    .icon {
        cursor: pointer;
        vertical-align: middle;
    }
}

.error {
    color: red;
}

.completed-dialog {
    h2 {
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 20px;
    }

    img {
        width: 40%;
    }
}
.discount-info {
    color: rgb(255, 54, 54);
    font-size:  15px; 
    margin: 10px 0; /* Adds top and bottom margin */
    // font-weight: bold;
}
.bold-message{
    font-weight: bold;
}
.dinfo {
   
    font-size: 15px; 
    margin: 10px 0; /* Adds top and bottom margin */
}

.popover-time {
    
    padding: 10px;
    max-width: 300px;

    div {
        display: inline-block;
        border-radius: 5px;
        border: 1px solid #ddd;
        padding: 5px;
        margin: 5px;
        cursor: pointer;
    }
}

.timePick {
    display: inline-block;
    padding: 15px 20px;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #D4D0D0;
}

.payment-dialog {
    .method-cont {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;

        span {
            display: block;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .button-cont {
        display: flex;
        justify-content: space-between !important;
    }

    .payment-method {
        border: 1px solid #ddd;
        padding: 10px;
        cursor: pointer;
        margin: 5px 5px;
        flex: 1;
        display: inline-block;
        border-radius: 3px;

        img {
            width: 100px;
        }

        &.active {
            border: 2px solid #dcb300;
        }
    }
}