@font-face {
    font-family: 'frank';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: url('./../fonts/frank-regular.ttf') format('TrueType');
}

@font-face {
    font-family: 'frank';
    font-style: normal;
    font-weight: bold;
    font-stretch: 100%;
    font-display: swap;
    src: url('./../fonts/frank-bold.ttf') format('TrueType');
}

@font-face {
    font-family: 'frank';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url('./../fonts/frank-medium.ttf') format('TrueType');
}
@font-face {
    font-family: 'frank';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url('./../fonts/frank-bold.ttf') format('TrueType');
}