.LoginPage {
    overflow: hidden;

    @media only screen and (max-width: 600px) {
        overflow-y: scroll;
    }

    .logo {
        display: block;
        width: 100%;
        padding: 0% 5%;
        text-align: center;

        img {
            width: 35%;
            cursor: pointer;

            @media only screen and (max-width: 600px) {
                width: 70%;
            }
        }
    }

    .carb {
        // transform: scale(-1, 1);
        width: 125%;
        margin-left: -100px;
        margin-top: 15%;
    }

    .error {
        color: red;
        font-size: 14px;
        font-weight: 500;
    }

    .division {
        display: flex;

        .div-block {
            height: 100vh;
            flex: 1 0;
        }

        .left {
            padding: 0% 5%;
            display: flex;
            flex-direction: column;
        }

        .right {
            display: block;
            background-color: var(--primary-yellow);

            @media only screen and (max-width: 600px) {
                display: none;
            }

        }

        .form--login {
            text-align: center;
            padding: 1% 25%;

            @media only screen and (max-width: 600px) {
                padding: 1% 1%;
            }

            h3 {
                font-family: 'frank', cursive !important;
                font-weight: bold;
                font-size: 32px;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 15px;
            }

            .InputBox {
                margin: 15px 0px;
            }

            .Button {
                margin: 10px 0px;
            }

            .forgot-link {
                font-weight: 500;
                font-size: 14px;
                display: block;
                padding: 5px 0px;
                cursor: pointer;
            }
        }
    }
}

.popover-logout {
    li {
        padding: 10px 20px;
        list-style: none;
        min-width: 120px;
        cursor: pointer;

        &:hover {
            background-color: var(--joy-palette-primary-outlinedHoverBg);
        }
    }
}

.ToggleComponent {
    background: #F0EFF2;
    padding: 5px;
    display: block;
    border-radius: 5px;
    font-weight: bold;

    a {
        display: inline-block;
        width: 50%;
        cursor: pointer;
        color: #666;
    }

    .active {
        background-color: #fff;
        padding: 15px 20px;
        border-radius: 5px;
        color: var(--primary-black);
    }
}

.logout-Menu {
    position: absolute;
    right: 3%;
    top: 3%;
    cursor: pointer;

    .menu-white {
        display: flex;
        background: #ffffffad;
        border-radius: 100px;
        cursor: pointer;
        width: 100px;

        .menu-user {
            padding: 10px 13px;
            border-radius: 100px;
            background-color: #fff;
        }

        .menu-icon {
            padding: 10px;
        }
    }
}

.resend {
    padding: 15px 0px;
    font-weight: 600;

    .disabled {
        cursor: not-allowed;
        color: #aaa;
    }

    .request {
        color: var(--primary-yellow);
        cursor: pointer;
    }

    .time {
        color: #aaa;
    }
}