.Button{
    display: block;
    padding: 15px;
    background: #ddd;
    border-radius: 5px;
    text-align: center;
    min-width: 100px;
    font-weight: bold;
    cursor: pointer;
    &.yellow{
        color: var(--primary-black);
        background: var(--primary-yellow);
    }
    &.border{
        border: 1px solid var(--primary-black);
        color: var(--primary-black);
        background: #fff;
        &:hover{
            background-color: var(--joy-palette-primary-outlinedHoverBg)
        }
    }
    &.disabled{
        cursor: not-allowed;
        background-color: #ddd;
        &:hover{
            background-color: #ddd;
        }
    }
    &:hover{
        background-color: var(--joy-palette-primary-solidHoverBg)
    }
}